<template>
    <v-container>
        <!-- <h1 class="headline">Conference Call</h1> -->

         <v-data-table
    :headers="headers"
    :items="globalNotification"
    sort-by="name"
    class="elevation-1"
    :options.sync="options"
      :server-items-length="count"
      :items-per-page="10"
      :loading="loading"
  >
  <template v-slot:[`item.createdDate`]="{ item }">
    {{formatDate(item.createdDate, "Do MMM YYYY hh:mm A")}}
  </template>

  
 
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Global Notification</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>  
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openNotificationDialog">New Notification</v-btn>      
      </v-toolbar>
    </template>
    
    <template v-slot:no-data>
        No Data
      
    </template>
  </v-data-table>
    


 <v-dialog v-model="notificationDialog" max-width="600">
      <v-toolbar color="primary" dark
        >Create New Notification

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card class="pa-5">
        

        <v-text-field
          label="Title"
          v-model="notification.title"
        ></v-text-field>
         <v-text-field
          label="Body"
          v-model="notification.body"
        ></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="notificationDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="submit"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-container>
</template>


<script>
export default {
    data(){
        return{
            globalNotification: [],
            notification: {},
            notificationDialog: false,
              headers:[
                    {text: 'Id', value: 'id'},
                    {text: 'Title', value: 'title'},
                    {text: 'Category', value: 'category'},
                    {text: 'Body', value: 'body'},
                    {text: 'Date', value: 'createdDate'},
                 
            ],
                count: null,
    loading:false,
    options:{},
        }
    },
      watch: {
     options:{
       handler () {
          this.getGlobalNotification()
        },
        deep: true,
    }
  },
    methods:{
        openNotificationDialog(){
            this.notificationDialog = true;
        },
        getGlobalNotification(){
            this.$General.getGlobalNotification().then(data=>{
                this.globalNotification = data.data.data.list;
                 this.count = data.data.data.count;
                this.loading = false;
            }).catch(error=>{
                console.log(error);
                this.loading = false;
            })
        },
        submit(){
            this.loading = true;
            this.$General.postGlobalNotification(this.notification).then(data=>{
                this.notificationDialog = false;
                this.loading = false;
                this.notification = {};
                this.getGlobalNotification();
            }).catch(error=>{
                console.log(error);            })
        }
    },
    mounted(){
        this.getGlobalNotification();
    }
}
</script>


<style scoped>

</style>